import type { SyntheticEvent, PropsWithChildren } from 'react';
import React from 'react';

import * as s from './ConfirmModal.styled';

interface Props {
  onClose: () => void;
}

export const ConfirmModal: React.FC<PropsWithChildren<Props>> = ({ onClose, children }) => {
  const handleChildrenClick = (e: SyntheticEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <s.Container onClick={onClose}>
      <s.Wrapper onClick={handleChildrenClick}>{children}</s.Wrapper>
    </s.Container>
  );
};

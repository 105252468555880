import React from 'react';

import styled from 'styled-components';
import { LoadingBar } from './loader.styled';

export const InlineLoader: React.FC = () => <LoadingBar data-testid="loader" />;

export const LoaderWrapper = styled.div`
  position: relative;

  > div {
    position: absolute;
    top: 0;
    left: 0;
  }
`;
export const WrappedInlineLoader: React.FC = () => (
  <LoaderWrapper>
    <InlineLoader />
  </LoaderWrapper>
);

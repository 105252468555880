import React from 'react';
import * as s from './LoaderComponent.styled';

interface Props {
  ringStyleClass?: string;
}

export const LoaderComponent: React.FC<Props> = ({ ringStyleClass }) => {
  return (
    <s.Loading role="alert" aria-live="assertive" aria-label="loading something, please wait">
      <div className={`lds-ring ${ringStyleClass}`}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </s.Loading>
  );
};

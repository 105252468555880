import React from 'react';
import type { FormatNumberOptions } from 'react-intl';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { SupportedCurrencyEnum } from '@goparrot/common';
import { intl } from '../services/v2';

export const getFormattedNumber: (value: number) => React.ReactElement = (value: number) => (
  <FormattedNumber value={value} style="currency" currency={SupportedCurrencyEnum.USD} />
);

export const getFormattedMessage: (path: string) => React.ReactElement = (path: string) => <FormattedMessage id={path} />;

export const getIntlString = (path: string, values?: any) => intl.formatMessage({ id: path }, values);

export const intlPriceOptions: FormatNumberOptions = {
  style: 'currency',
  currency: SupportedCurrencyEnum.USD,
};

export const getIntlNumber = (value: number, options: FormatNumberOptions = intlPriceOptions) => intl.formatNumber(value, options);
